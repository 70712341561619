import { FieldPreset } from './field-types'

export type PremiumRestriction = {
  forms: { limit: number; threshold?: number }
  fields: { limit: number; threshold?: number }
  submissions: { limit: number; threshold?: number }
  allowedFields: { uploadButton?: boolean; generalUploadButton?: boolean }
  isTopPremium?: boolean
}

export enum upgradeAlertType {
  FIELDS_LIMIT = 'fieldsLimit',
}

export const DEFAULT_RESTRICTIONS: PremiumRestriction = {
  forms: { limit: 5, threshold: 3 },
  fields: { limit: 10, threshold: 7 },
  submissions: { limit: 100, threshold: 70 },
  allowedFields: {},
}

export const getAscendPackagePickerUrl = (msid, appDefId, origin, vertical) =>
  `https://www.wix.com/apps/upgrade?metaSiteId=${msid}&appDefId=${appDefId}&pp_origin=${origin}_${vertical}`

export enum BillingPanelReferrer {
  GFPP = 'GPFF',
  MANAGE_FIELDS_LINK = 'manage_fields',
  ADD_FIELD_LINK = 'add_new_field',
  NUMBER_OF_FIELDS_ALERT = 'number_of_fields_alert',
  UPLOAD_FIELD_ALERT = 'upload_field_alert',
}

export const referrerByAlertType = {
  [upgradeAlertType.FIELDS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FIELDS_ALERT,
  [FieldPreset.GENERAL_UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
}
