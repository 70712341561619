import { ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { FieldProperties, FieldData } from '../../field-types-data'
import translations from '../../../../services/translations'
import { ConnectedElementPreset } from '../../../../../../constants/field-types'
import { iconNames } from '../../icons/types'

const makeLinkToLogin = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      data: {
        text: t(`fieldTypes.${ConnectedElementPreset.REGISTRATION_FORM_LINK_TO_LOGIN}.text`),
      },
    },
  }
}

export const linkToLoginData: FieldData = {
  make: makeLinkToLogin,
  customFields: [],
  icon: iconNames.link
}
