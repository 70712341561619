import * as _ from 'lodash'
import * as theme01 from '../../../assets/components/theme01.json'
import * as theme02 from '../../../assets/components/theme02.json'
import * as theme03 from '../../../assets/components/theme03.json'
import * as theme04 from '../../../assets/components/theme04.json'
import * as theme05 from '../../../assets/components/theme05.json'
import * as theme06 from '../../../assets/components/theme06.json'
import { Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG } from '../../../constants/roles'

const handlePropertiesAsStyle = properties => {
  const propertiesSource = _.mapValues(properties, 'theme')

  return comp => {
    return _.merge({}, comp, { style: { style: { properties, propertiesSource } } })
  }
}

export const customStyleHandlers = {
  [ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG]: (style, text) =>
    _.replace(style, 'TITLE', text),
}

export const applyStyle = (comp, theme) => {
  const style = getThemeStyle(theme)[comp.role]

  if (!style) {
    return comp
  }

  if (!_.isString(style)) {
    return handlePropertiesAsStyle(style)(comp)
  }

  const customStyleHandler = customStyleHandlers[comp.role]
  const text = comp.data.text
  const newText = customStyleHandler
    ? customStyleHandler(style, text)
    : _.replace(style, 'TITLE', innerText(text))

  return _.merge({}, comp, { data: { text: newText } })
}

export const applyPropsAndData = (comp, theme) =>
  _.merge({}, comp, {
    props: getThemeProps(theme)[comp.role],
    data: getThemeData(theme)[comp.role],
  })

const THEMES_STYLES = {
  [Theme.THEME01]: theme01,
  [Theme.THEME02]: theme02,
  [Theme.THEME03]: theme03,
  [Theme.THEME04]: theme04,
  [Theme.THEME05]: theme05,
  [Theme.THEME06]: theme06,
}

export const getThemeStyle = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'style'], {})
export const getThemeProps = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'props'], {})
export const getThemeData = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'data'], {})
export const removeRequiredIndicationFromTheme = themeStyle =>
  Object.keys(themeStyle).reduce((acc, key) => {
    acc[key] = _.omit(themeStyle[key], 'txtlblrq')
    return acc
  }, {})
