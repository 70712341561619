import * as _ from 'lodash'
import { safelyStringify, serializeError } from '../../utils/utils'
import { CONNECTED_ELEMENT_PREFIX, INPUT_FIELDS_PREFIX } from '../../constants/roles'

export const undoable = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = async function(...args) {
    const res = await originalMethod.apply(this, args)
    await this.boundEditorSDK.history.add({ label: 'History' })
    return res
  }
}

export const withBi = ({ startEvid = {}, endEvid = {} } = {}) => (
  _target,
  _funcName,
  decorator
) => {
  const isBiData = arg => arg.startBi !== undefined || arg.endBi !== undefined
  const originalMethod = decorator.value

  decorator.value = async function(...args) {
    let res
    const biData = args[args.length - 1]

    if (isBiData(biData)) {
      if (!_.isEmpty(biData.startBi)) {
        this.biLogger.log({ evid: startEvid, ...biData.startBi })
      }
      res = await originalMethod.apply(this, args.slice(0, -1))
      if (!_.isEmpty(biData.endBi)) {
        this.biLogger.log({ evid: endEvid, ...biData.endBi })
      }
    } else {
      res = await originalMethod.apply(this, args)
    }

    return res
  }
}

const wrapPublicApi = (f, funcName, ravenInstance): Function => {
  return (...args) => {
    const stringifiedArgs = safelyStringify(args)
    ravenInstance.captureBreadcrumb({
      message: `[core-api] '${funcName}'`,
      category: 'core-api',
      data: {
        name: funcName,
        args: stringifiedArgs,
      },
      level: 'info',
    })
    return f(...args).catch(err => {
      console.error(err)
      ravenInstance.captureException(err, {
        tags: { 'core-api': funcName },
        extra: {
          args: stringifiedArgs,
          error: serializeError(err),
        },
      })
      return null
    })
  }
}

const generateRuntimeApi = (
  obj,
  startObject,
  ravenInstance,
  funcNameTransformer = funcName => funcName
) => {
  return _.reduce(
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj)),
    (apiObj, funcName) => {
      const f = obj[funcName].bind(obj)
      return _.merge(apiObj, {
        [funcNameTransformer(funcName)]: _.startsWith(funcName, '_')
          ? f
          : wrapPublicApi(f, funcName, ravenInstance),
      })
    },
    startObject
  )
}

const generateRuntimeApis = (apis, ravenInstance) => {
  const toPublicApi = apiName => {
    const funcNameTransformer = funcName => `${apiName}.${funcName}`
    return generateRuntimeApi(apis[apiName], {}, ravenInstance, funcNameTransformer)
  }

  const apisNames = Object.keys(apis)
  const apisFunctions = apisNames.map(toPublicApi)

  return _.assign({}, ...apisFunctions)
}

export const generateRuntimeCoreApi = (coreApi, apis, ravenInstance) => {
  const runtimeApis = generateRuntimeApis(apis, ravenInstance)
  return generateRuntimeApi(coreApi, runtimeApis, ravenInstance)
}

export const isInputField = (role: string) => _.startsWith(role, INPUT_FIELDS_PREFIX)

export const isAnyField = (role: string) =>
  isInputField(role) || _.startsWith(role, CONNECTED_ELEMENT_PREFIX)
