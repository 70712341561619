import * as _ from 'lodash'
import { FIELDS } from '../../../../../../constants/roles'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { generalTextData } from '../../general-fields/definitions/general-text'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { FieldRenderConfigType } from '../../constants';
import { FormPlugin } from '../../../../../../constants/plugins';

const makeLoginEmail = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL,
      connectionConfig: {
        crmType: CRM_TYPES.EMAIL,
        crmLabel: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL}`),
      },
      data: {
        textType: 'email',
      },
      props: {
        placeholder: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL}`),
        required: true,
      },
    },
  })
}

export const loginEmailData: FieldData = {
  make: makeLoginEmail,
  customFields: [],
  icon: iconNames.email,
  renderConfig: {
    [FormPlugin.REGISTRATION_FORM]: {
      isMandatory: true,
      crmSync: FieldRenderConfigType.REMOVE,
      required: FieldRenderConfigType.DISABLED,
      internalName: FieldRenderConfigType.DISABLED,
      checkedByDefault: FieldRenderConfigType.DISABLED,
    }
  }
}
