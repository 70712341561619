import { undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, ComponentConnection, MediaManagerUploadedObjectResponse } from '../api-types'
import CoreApi from '../core-api'
import {
  DEFAULT_LINK_REQUEST,
  SuccessActionTypes,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_OLD,
} from '../../../constants/success-settings'
import { ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { createHiddenMessage, createSubmitButton, getExtraMessageText } from '../services/form-service'
import { LinkPanelTypes } from '../../../panels/settings-panel/constants'
import { LinkTypes } from '../../../panels/settings-panel/constants'
import { HIDDEN_MESSAGE, MESSAGE_EXTRA_HEIGHT, SUBMIT } from './consts/heights'
import { innerText } from '../../../utils/utils'
import * as _ from 'lodash'
import { ButtonType, MessageType } from '../../../constants/field-types'
import { mediaTypes } from '../../../panels/settings-panel/constants'
import { DEFAULT_UPLOAD_OBJECT } from './consts/MediaManager'

export default class SettingsApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any
  private editorSDK: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, remoteApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.editorSDK = editorSDK
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection(componentRef: ComponentRef, _biData = {}): Promise<string> {
    return this.coreApi.createCollection(componentRef)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(connectToRef: ComponentRef, labels: string[], _biData = {}) {
    const { controllerRef, role, config } = await this.coreApi.getComponentConnection(connectToRef)
    config.labels = labels

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: config,
      isPrimary: true,
    })
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { successActionType })
    if (successActionType === SuccessActionTypes.SHOW_MESSAGE) {
      await this._addHiddenMessage(connectToRef)
    } else {
      this.removeSuccessMessage(connectToRef)
    }
  }

  @undoable()
  public addHiddenMessage(componentRef: ComponentRef) {
    return this._addHiddenMessage(componentRef)
  }

  public async removeSuccessMessage(componentRef: ComponentRef) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { messageRef, messageLayout, boxLayout }
    }
    const update = async (boxLayout, messageLayout) => {
      await this.boundEditorSDK.components.layout.update({
        componentRef,
        layout: { height: boxLayout.height - (messageLayout.height + MESSAGE_EXTRA_HEIGHT) },
      })
    }

    const { messageRef, messageLayout, boxLayout } = await get()
    if (!boxLayout || !messageLayout) {
      return
    }
    await update(boxLayout, messageLayout)
    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanelOld(componentRef: ComponentRef) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    const linkObject = await this.boundEditorSDK.editor.openLinkPanel({
      value: successLinkValue,
      visibleSections: VISIBLE_LINK_PANEL_SECTIONS_OLD,
    })

    await this.setComponentConnection(componentRef, { successLinkValue: linkObject })
    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })
    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)
    return { successLinkText, successLinkType, successLinkSubType }
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, successActionType: SuccessActionTypes) {

    const linkRequest = await this._getLinkRequest(componentRef, successActionType)

    const linkObject = await this.boundEditorSDK.editor.openLinkPanel({
      value: linkRequest,
      visibleSections: VISIBLE_LINK_PANEL_SECTIONS,
    })

    await this.setComponentConnection(componentRef, { successLinkValue: linkObject })

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)
    const chosenLinkType = (linkObject && linkObject.type) ? linkObject.type : LinkPanelTypes.NONE

    return {chosenLinkType, successLinkText, successLinkType, successLinkSubType }
  }

  private async _getLinkRequest(componentRef: ComponentRef, successActionType: SuccessActionTypes) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    if (!successLinkValue) {
      successLinkValue =  DEFAULT_LINK_REQUEST
    }

    switch (successActionType) {
      case SuccessActionTypes.LINK: {
        successLinkValue.type = LinkPanelTypes.PAGE
        break
      }
      case SuccessActionTypes.EXTERNAL_LINK:
        successLinkValue.type = LinkPanelTypes.EXTERNAL_LINK
        break

      default:
        break
    }

    return successLinkValue

  }

  @undoable()
  public async updateDownloadSection(componentRef: ComponentRef) {

    let {
      config: {submitOptionsUploadedObject },
    } = await this.coreApi.getComponentConnection(componentRef)

    if (!submitOptionsUploadedObject) {
      submitOptionsUploadedObject = DEFAULT_UPLOAD_OBJECT
    }

    try {
      const uploadedObject: Array<MediaManagerUploadedObjectResponse> = await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: mediaTypes.DOCUMENT,
        isMultiSelect: false,
      })

      if (uploadedObject) {
        submitOptionsUploadedObject.docId = _.head(uploadedObject).uri
        submitOptionsUploadedObject.name = _.head(uploadedObject).title
        submitOptionsUploadedObject.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      submitOptionsUploadedObject.status = UploadStatuses.UPLOAD_FAILED
    }

    await this.setComponentConnection(componentRef, {
      submitOptionsUploadedObject: submitOptionsUploadedObject,
    })

    return { submitOptionsFileUploadStatus: submitOptionsUploadedObject.status, submitOptionsFileUploadName: submitOptionsUploadedObject.name }
  }

  public async getUploadedFileNameAndStatus(componentRef) {
    let {
      config: { submitOptionsUploadedObject },
    } = await this.coreApi.getComponentConnection(componentRef)

    if (!submitOptionsUploadedObject) {
      submitOptionsUploadedObject = DEFAULT_UPLOAD_OBJECT
    }

    return { submitOptionsFileUploadStatus: submitOptionsUploadedObject.status, submitOptionsFileUploadName: submitOptionsUploadedObject.name }
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(componentRef, emailId, email, _biData = {}) {
    const { id } = await this.remoteApi.insertEmail(email)
    return this.setComponentConnection(componentRef, { [emailId]: id })
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(componentRef: ComponentRef, newSuccessMessage, _biData = {}) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data }, newSuccessMessage),
    })
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getTags() {
    return this.remoteApi.getTags()
  }

  @undoable()
  public async addSubmitButton(componentRef: ComponentRef) {
    const get = async () => {
      const {
        controllerRef,
        config: { theme },
      } = await this.coreApi.getComponentConnection(componentRef)
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { controllerRef, theme, boxLayout }
    }

    const update = async (theme, controllerRef, boxLayout) => {
      const layout = { layout: { y: boxLayout.height - SUBMIT.BUTTON_HEIGHT } }
      const buttonType = (await this.coreApi.isRegistrationForm(componentRef))
        ? ButtonType.SIGNUP
        : ButtonType.SUBMIT

      const { connectToRef } = await this.coreApi.addComponentAndConnect(
        createSubmitButton({ layout, buttonType, theme }),
        controllerRef,
        componentRef,
      )

      const fieldLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: connectToRef,
      })

      const updateBoxLayout = async boxRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
        return this.boundEditorSDK.components.layout.update({
          componentRef: boxRef,
          layout: { height: boxLayout.height + fieldLayout.height + 32 },
        })
      }

      const updateAncestorsBoxLayout = () => this.boundEditorSDK.components
        .getAncestors({ componentRef })
        .then(ancestors => Promise.all(_.map(ancestors, updateBoxLayout)))


      // tslint:disable-next-line:early-exit
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        await Promise.all([
          updateBoxLayout(componentRef),
          updateAncestorsBoxLayout()
        ]).then(() => this.coreApi.layout.centerComponentInsideLightbox(componentRef))
      }
    }

    const updateMessageLocation = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      if (!message) {
        return null
      }
      return this.boundEditorSDK.components.layout.update({
        componentRef: message.componentRef,
        layout: { y: message.y + SUBMIT.MESSAGE },
      })
    }

    const { controllerRef, theme, boxLayout } = await get()
    if (!boxLayout) {
      return null
    }
    await update(theme, controllerRef, boxLayout)
    return updateMessageLocation()
  }

  public async getMessage(componentRef: ComponentRef) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
    if (!messageRef) {
      return ''
    }
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })
    return { message: innerText(data.text) }
  }

  public async getEmails(componentRef) {
    const {
      config: { emailId, secondEmailId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return Promise.all([
      this.remoteApi.getEmailById(emailId),
      this.remoteApi.getEmailById(secondEmailId),
    ])
  }

  public async getCrucialElementsOld(componentRef: ComponentRef) {
    const { controllerRef }: ComponentConnection = await this.coreApi.getComponentConnection(
      componentRef,
    )

    const [
      submitButtonRef,
      messageRef,
      isSubscribeFieldExistsWithoutEmailField,
      {
        config: { successActionType },
      },
    ] = await Promise.all([
      // TODO: you can merge all of it to one call (componentRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE]) and filter locally
      this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE),
      this.coreApi.isSubscribeFieldExistsWithoutEmailField(controllerRef),
      this.coreApi.getComponentConnection(componentRef),
    ])
    return {
      isSubmitButtonExists: !!submitButtonRef,
      isHiddenMessageExists: successActionType === SuccessActionTypes.LINK || !!messageRef,
      isSubscribeFieldExistsWithoutEmailField,
    }
  }

  public async getCrucialElements(componentRef: ComponentRef) {
    const { controllerRef }: ComponentConnection = await this.coreApi.getComponentConnection(
      componentRef,
    )

    const [
      submitButtonRef,
      messageRef,
      isSubscribeFieldExistsWithoutEmailField,
      {
        config: { successActionType },
      },
    ] = await Promise.all([
      // TODO: you can merge all of it to one call (componentRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE]) and filter locally
      this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE),
      this.coreApi.isSubscribeFieldExistsWithoutEmailField(controllerRef),
      this.coreApi.getComponentConnection(componentRef),
    ])
    return {
      isSubmitButtonExists: !!submitButtonRef,
      isHiddenMessageExists:
        successActionType === SuccessActionTypes.LINK ||
        successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT || successActionType === SuccessActionTypes.EXTERNAL_LINK ||
        !!messageRef,
      isSubscribeFieldExistsWithoutEmailField,
    }
  }

  public async getSuccessLinkText(componentRef) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)
    return await this.boundEditorSDK.editor.utils.getLinkAsString({ link: successLinkValue })
  }

  public async getOtherFormsNames(componentRef: ComponentRef): Promise<string[]> {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef,
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      }),
    )
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {},
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels },
    } = await this.coreApi.getComponentConnection(connectToRef)
    if (formLabelId && _.includes(labels, formLabelId)) {
      await this.remoteApi.updateTag(formLabelId, formName)
    }
  }

  private async _addHiddenMessage(componentRef: ComponentRef) {
    const get = async () => {
      const getBoxLayout = async componentRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
        return Promise.resolve(boxLayout)
      }

      const boxLayout = await getBoxLayout(componentRef)
      const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)

      return { boxLayout, controllerRef }
    }

    const update = async (boxLayout, controllerRef) => {
      const layout = { y: boxLayout.height - HIDDEN_MESSAGE.HIDDEN_MESSAGE_HEIGHT }
      const hiddenMessageType = (await this.coreApi.isRegistrationForm(componentRef))
        ? MessageType.REGISTRATION
        : MessageType.HIDDEN

      const { connectToRef } = await this.coreApi.addComponentAndConnect(
        createHiddenMessage({ layout, hiddenMessageType }),
        controllerRef,
        componentRef,
      )

      const fieldLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: connectToRef,
      })


      const updateBoxLayout = async boxRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
        return this.boundEditorSDK.components.layout.update({
          componentRef: boxRef,
          layout: { height: boxLayout.height + fieldLayout.height + 32 },
        })
      }

      const updateAncestorsBoxLayout = () => this.boundEditorSDK.components
        .getAncestors({ componentRef })
        .then(ancestors => Promise.all(_.map(ancestors, updateBoxLayout)))


      // tslint:disable-next-line:early-exit
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        await Promise.all([
          updateBoxLayout(componentRef),
          updateAncestorsBoxLayout()
        ]).then(() => this.coreApi.layout.centerComponentInsideLightbox(componentRef))
      }
    }

    const { boxLayout, controllerRef } = await get()
    return update(boxLayout, controllerRef)
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if(_.get(linkObject, 'url')){
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }
}
