import { ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { ConnectedElementPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { iconNames } from '../../icons/types'

const makeCodeOfConduct = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT,
      data: {
        text: `<p class="font_8"><span>${t(
          `fieldTypes.${ConnectedElementPreset.REGISTRATION_FORM_CODE_OF_CONDUCT}`
        )}</span></p>`,
      },
    },
  }
}

export const codeOfConductData: FieldData = {
  make: makeCodeOfConduct,
  customFields: [],
  icon: iconNames.link
}
