import { undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_TITLE } from '../../../constants/roles'
import * as _ from 'lodash'
import {
  FORM_PADDING,
  TOP_PADDING,
  FIELD_MARGIN,
  PADDING_FROM_TITLE,
  ROLE_PADDING,
  LAST_FIELD_PADDING,
} from './consts/padding'
import { EXTRA_COLSPANS } from './consts/columns'
import CoreApi from '../core-api'

export default class LayoutApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig: { columns: number },
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formLayoutPanel.CHANGE_LAYOUT })
  public async updateFieldsLayout(
    componentRef: ComponentRef,
    columnsNumber: number,
    _biData = {}
  ): Promise<void> {
    const fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const titleHeight = await this.updateComponentByTitle(componentRef)
    const lastFieldLayout = await this._updateFields(
      componentRef,
      fields,
      columnsNumber,
      titleHeight
    )

    await Promise.all([
      this.updateComponentLayoutByRole(
        componentRef,
        ROLE_SUBMIT_BUTTON,
        ROLE_PADDING.SUBMIT,
        lastFieldLayout
      ),
      this.updateComponentLayoutByRole(
        componentRef,
        ROLE_MESSAGE,
        ROLE_PADDING.MESSAGE,
        lastFieldLayout
      ),
    ])
    return this.updateYWithPadding(componentRef, LAST_FIELD_PADDING, lastFieldLayout, 'height')
  }

  public async updateComponentByTitle(componentRef: ComponentRef): Promise<number> {
    const titleComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_TITLE)
    if (!titleComponentRef) {
      return 0
    }

    const { height } = await this.boundEditorSDK.components.layout.get({
      componentRef: titleComponentRef,
    })
    await this.boundEditorSDK.components.layout.update({
      componentRef: titleComponentRef,
      layout: { x: FORM_PADDING, y: TOP_PADDING },
    })

    return height
  }

  public async getChildrenLayouts(componentRef: ComponentRef, childRoles: string[] | string) {
    if (_.isString(childRoles)) {
      childRoles = [childRoles]
    }
    const pred = role => _.includes(childRoles, role)

    const childComps = await this.boundEditorSDK.components.getChildren({ componentRef })
    const childrenLayouts = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.coreApi.getComponentConnection(child)
        if (pred(role)) {
          const layout = await this.boundEditorSDK.components.layout.get({ componentRef: child })
          return { componentRef: child, ...layout }
        }
        return null
      })
    )
    return _.filter(childrenLayouts, x => !!x)
  }

  public async updateComponentLayoutByRole(
    componentRef: ComponentRef,
    role: string,
    padding: number,
    layout
  ) {
    const roleLayouts = await this.getChildrenLayouts(componentRef, role)
    const firstRoleComponentRef = _.get(roleLayouts, [0, 'componentRef'])

    return this.updateYWithPadding(firstRoleComponentRef, padding, layout)
  }

  public updateYWithPadding(componentRef: ComponentRef, padding, { y, maxHeight }, key = 'y') {
    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { [key]: y + maxHeight + padding },
    })
  }

  public async centerComponentInsideLightbox(componentRef: ComponentRef) {
    const { width, height, x, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })

    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: {
        x: _.max([0, x - width / 2]),
        y: _.max([0, y - height / 2]),
      },
    })
  }

  private _updateFields(
    componentRef: ComponentRef,
    fields,
    columnsNumber: number,
    titleHeight: number = 0
  ) {
    const reduceFunc = async (prevFieldLayout, field) => {
      const { startX, x, y, maxHeight, defaultWidth, currentCol } = await prevFieldLayout
      const colSize = await this._getFieldColSize(field.componentRef, columnsNumber)
      const width = colSize * defaultWidth + (colSize - 1) * FIELD_MARGIN
      let nextCol = currentCol + colSize
      let layout = { width, x: x + width + FIELD_MARGIN, y }
      let overrideMaxHeight = -1
      if (nextCol > columnsNumber) {
        nextCol = colSize
        layout = { width, x: startX, y: y + maxHeight + 32 }
        overrideMaxHeight = 0
      }
      await this.boundEditorSDK.components.layout.update({
        componentRef: field.componentRef,
        layout,
      })
      return {
        startX,
        x: layout.x,
        y: layout.y,
        width,
        defaultWidth,
        maxHeight: _.max([overrideMaxHeight > -1 ? overrideMaxHeight : maxHeight, field.height]),
        currentCol: nextCol,
      }
    }

    const getBoxConfig = async (
      componentRef: ComponentRef,
      columns: number,
      titleHeight: number
    ) => {
      const { width } = await this.boundEditorSDK.components.layout.get({ componentRef })
      const titleExtraHeight = titleHeight > 0 ? titleHeight + PADDING_FROM_TITLE : 0

      return {
        startX: FORM_PADDING,
        currentCol: columns,
        x: FORM_PADDING,
        y: 0,
        width: 0,
        maxHeight: FORM_PADDING + titleExtraHeight - FIELD_MARGIN,
        defaultWidth: (width - FORM_PADDING * 2 - (columns - 1) * FIELD_MARGIN) / columns,
      }
    }

    return _.reduce(fields, reduceFunc, getBoxConfig(componentRef, columnsNumber, titleHeight))
  }

  private async _getFieldColSize(componentRef: ComponentRef, columns: number): Promise<number> {
    const type = await this.boundEditorSDK.components.getType({ componentRef })
    return _.min([EXTRA_COLSPANS[type] || 0, columns - 1]) + 1
  }
}
