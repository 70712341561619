export const preset = t => ({
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 384,
        height: 29,
        x: 71,
        y: 57,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: {
          isPreset: true,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'title_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        text: '<h6 class="font_6">Sign Up Today</h6>',
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 300,
        height: 50,
        x: 71,
        y: 134,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6wa1',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jowmxhfd',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '1',
            'alpha-btn_brd': '0.55',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_18',
            brw: '0px 0px 2px 0px',
            brwd: '1',
            brwe: '0px 0px 2px 0px',
            brwf: '0px 0px 2px 0px',
            brwh: '0px 0px 2px 0px',
            btn_brd: 'color_15',
            btn_brw: '1',
            btn_fnt: 'font_8',
            fnt: 'font_9',
            fnt2: 'font_8',
            fntlbl: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-placeholder': 'color_14',
            txt2: 'color_14',
            txtd: '#DBDBDB',
            txtlbl: 'color_14',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            'alpha-btn_brd': 'value',
            bg: 'theme',
            bgd: 'value',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brd: 'theme',
            btn_brw: 'value',
            btn_fnt: 'theme',
            fnt: 'theme',
            fnt2: 'theme',
            fntlbl: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            'txt-placeholder': 'theme',
            txt2: 'theme',
            txtd: 'value',
            txtlbl: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmType: 'firstName',
        crmLabel: t('preset.firstNameCrmLabel'),

        fieldType: 'firstName',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        inputHeight: 50,
        labelMargin: 9,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 10,
        placeholder: 'F I R S T    N A M E',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
        maxLength: 100,
        label: '',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 300,
        height: 50,
        x: 421,
        y: 134,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6v62',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jowmzyb6',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '1',
            'alpha-btn_brd': '0.55',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_18',
            brw: '0px 0px 2px 0px',
            brwd: '1',
            brwe: '0px 0px 2px 0px',
            brwf: '0px 0px 2px 0px',
            brwh: '0px 0px 2px 0px',
            btn_brd: 'color_15',
            btn_brw: '1',
            btn_fnt: 'font_8',
            fnt: 'font_9',
            fnt2: 'font_8',
            fntlbl: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-placeholder': 'color_14',
            txt2: 'color_14',
            txtd: '#DBDBDB',
            txtlbl: 'color_14',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            'alpha-btn_brd': 'value',
            bg: 'theme',
            bgd: 'value',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brd: 'theme',
            btn_brw: 'value',
            btn_fnt: 'theme',
            fnt: 'theme',
            fnt2: 'theme',
            fntlbl: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            'txt-placeholder': 'theme',
            txt2: 'theme',
            txtd: 'value',
            txtlbl: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.lastNameCrmLabel'),

        fieldType: 'lastName',
        crmType: 'lastName',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        inputHeight: 50,
        labelMargin: 9,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 10,
        placeholder: 'L A S T    N A M E',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
        maxLength: 100,
        label: '',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 300,
        height: 50,
        x: 71,
        y: 228,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6vd2',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jowmxvts',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '1',
            'alpha-btn_brd': '0.55',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_18',
            brw: '0px 0px 2px 0px',
            brwd: '1',
            brwe: '0px 0px 2px 0px',
            brwf: '0px 0px 2px 0px',
            brwh: '0px 0px 2px 0px',
            btn_brd: 'color_15',
            btn_brw: '1',
            btn_fnt: 'font_8',
            fnt: 'font_9',
            fnt2: 'font_8',
            fntlbl: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-placeholder': 'color_14',
            txt2: 'color_14',
            txtd: '#DBDBDB',
            txtlbl: 'color_14',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            'alpha-btn_brd': 'value',
            bg: 'theme',
            bgd: 'value',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brd: 'theme',
            btn_brw: 'value',
            btn_fnt: 'theme',
            fnt: 'theme',
            fnt2: 'theme',
            fntlbl: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            'txt-placeholder': 'theme',
            txt2: 'theme',
            txtd: 'value',
            txtlbl: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_regForm_loginEmail',
      config: {
        crmLabel: t('preset.emailCrmLabel'),

        crmType: 'email',
        fieldType: 'regForm_loginEmail',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: true,
        inputHeight: 50,
        labelMargin: 9,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 10,
        placeholder: 'E M A I L',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'email',
        maxLength: 250,
        label: '',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 300,
        height: 50,
        x: 421,
        y: 228,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-j9445bid',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jown2vkk',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '1',
            'alpha-btn_brd': '0.55',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_18',
            brw: '0px 0px 2px 0px',
            brwd: '1',
            brwe: '0px 0px 2px 0px',
            brwf: '0px 0px 2px 0px',
            brwh: '0px 0px 2px 0px',
            btn_brd: 'color_15',
            btn_brw: '1',
            btn_fnt: 'font_8',
            fnt: 'font_9',
            fnt2: 'font_8',
            fntlbl: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            'txt-placeholder': 'color_14',
            txt2: 'color_14',
            txtd: '#DBDBDB',
            txtlbl: 'color_14',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            'alpha-btn_brd': 'value',
            bg: 'theme',
            bgd: 'value',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brd: 'theme',
            btn_brw: 'value',
            btn_fnt: 'theme',
            fnt: 'theme',
            fnt2: 'theme',
            fntlbl: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            'txt-placeholder': 'theme',
            txt2: 'theme',
            txtd: 'value',
            txtlbl: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_regForm_password',
      config: {
        crmLabel: t('preset.passwordCrmLabel'),

        fieldType: 'regForm_password',
        crmType: 'customField',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: true,
        inputHeight: 50,
        labelMargin: 9,
        labelPadding: 0,
        textAlignment: 'left',
        textPadding: 10,
        placeholder: 'P A S S W O R D',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'password',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.button.BasicButton',
      layout: {
        width: 290,
        height: 55,
        x: 251,
        y: 331,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6uz2',
      componentType: 'wysiwyg.viewer.components.SiteButton',
      style: {
        type: 'TopLevelStyle',
        id: 'style-joy1s81t',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brdh': '0',
            'alpha-txt': '1',
            'alpha-txth': '1',
            bg: 'color_15',
            bgh: 'color_18',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdh: 'color_11',
            brw: '0',
            fnt: 'font_6',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_11',
            txth: 'color_11',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brdh': 'value',
            'alpha-txt': 'value',
            'alpha-txth': 'value',
            bg: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdh: 'theme',
            brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txth: 'theme',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.SiteButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      role: 'button_Role',
      config: {},
      props: {
        type: 'ButtonProperties',
        metaData: {
          schemaVersion: '1.0',
        },
        align: 'center',
        margin: 0,
      },
      data: {
        type: 'LinkableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        label: 'Sign up',
        link: {
          type: 'FormSubmitButtonLink',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 661,
        height: 18,
        x: 66,
        y: 407,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: {
          isPreset: true,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'connectedElement_role_regForm_linkToLoginDialog',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        text:
          '<p class="font_8" style="text-align:center"><span class="color_15">&nbsp;Already a member? <span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span style="font-weight:bold">Log In</span></span></span></p>',
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 793,
        height: 18,
        x: 0,
        y: 460,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: {
          isPreset: true,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'message_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        text:
          '<p class="font_8" style="text-align:center"><span style="color:#05C8A1">Your success or error messages will be shown here.</span></p>',
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    width: 793,
    height: 537,
    x: 0,
    y: 0,
    scale: 1.0,
    rotationInDegrees: 0.0,
    fixedPosition: false,
  },
  style: {
    type: 'TopLevelStyle',
    id: 'style-jowlatda',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {},
      propertiesSource: {},
      groups: {},
    },
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  },
  role: 'form_Role',
  componentType: 'wysiwyg.viewer.components.FormContainer',
  config: {
    preset: 'registration-form',
    labels: ['contacts-contacted_me'],
    emailId: '',
    plugins: [{ id: 'registration-form' }],
  },
})
