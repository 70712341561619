import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { generalUrlData } from '../../general-fields/definitions/general-url'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

const makeWebsite = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalUrlData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.WEBSITE,
        crmTag: CRM_TAGS.OTHER,
        crmLabel: t(`fieldTypes.${FieldPreset.WEBSITE}`),
      },
      props: {
        placeholder: t(`fieldTypes.${FieldPreset.WEBSITE}`),
      },
    },
  })
}

export const websiteData: FieldData = {
  make: makeWebsite,
  customFields: [],
  icon: generalUrlData.icon
}
