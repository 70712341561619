import * as _ from 'lodash'
import { FONT_STYLE_VALUE_PREFIX, FormAlphaStyle, FormStyle } from '../../../constants/form-style'
import { DESIGN_GROUPS, getGroupType, roleDesignMapping } from '../manifests/global-design-manifest'
import { FieldPreset } from '../../../constants/field-types'
import { getFieldProperties } from '../preset/fields/field-types-data'

export const PARAM_TYPE_DEFAULT_VALUE = {
  ALPHA: 1,
  BG_COLOR_ALPHA: '#ffffff',
  BORDER_COLOR_ALPHA: '#e3e3e3',
  BORDER_SIZES: '1px',
  BORDER_RADIUS: 0,
  TEXT_COLOR: '#000000',
  FONT: 'font_8',
}

export const getFieldCompStyleProps = commonStyles => {
  return _.reduce(
    COMMON_STYLES_MAPPING,
    (result, styleConfig, styleName) =>
      _.assign(result, styleConfig.getStyleParams(commonStyles[styleName])),
    {}
  )
}

export type designMapping = { [key in DESIGN_GROUPS]?: string[] }
export type commonStyles = { [key in DESIGN_GROUPS]?: { value: string | number; alpha?: number } }

const calcCommonValueInArray = a => {
  const frequency = {}
  let max = 0
  let result: string
  for (let v in a) {
    frequency[a[v]] = (frequency[a[v]] || 0) + 1
    if (frequency[a[v]] > max) {
      max = frequency[a[v]]
      result = a[v]
    }
  }
  return result
}

const getGroupValuesFromStyle = (
  group: DESIGN_GROUPS,
  style: any,
  designMapping: designMapping,
  groupType: string,
  prefix?: string
) => {
  const mappedStyleParams = designMapping[group]
  return !mappedStyleParams ? [PARAM_TYPE_DEFAULT_VALUE[groupType]] : mappedStyleParams.map(p => style[`${prefix}${p}`] || PARAM_TYPE_DEFAULT_VALUE[groupType])
}

const getGroupValuesFromStyles = (
  group: DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[],
  groupType: string,
  prefix: string = ''
) => {
  return _.flatMap(fields, ({ style, designMapping }) =>
    getGroupValuesFromStyle(group, style, designMapping, groupType, prefix)
  )
}

export const getGroupValue = (
  group: DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[]
) => {
  const groupType = getGroupType(group)
  const groupValues = getGroupValuesFromStyles(group, fields, groupType)
  const groupValue = calcCommonValueInArray(groupValues)

  if (groupType.includes('ALPHA')) {
    const alphaValues = getGroupValuesFromStyles(group, fields, 'ALPHA', 'alpha-')
    const alphaValue = calcCommonValueInArray(alphaValues)
    return { value: groupValue, alpha: alphaValue }
  }

  return { value: groupValue }
}

export const calcCommonStyleGlobalDesign = (
  fields: { style: any; designMapping: designMapping }[]
): commonStyles =>
  _.reduce(
    DESIGN_GROUPS,
    (acc, group) => {
      acc[group] = getGroupValue(group, fields)
      return acc
    },
    {}
  )

export const getFieldStyle = (commonStyles: commonStyles, fieldType: FieldPreset) => {
  const {
    componentType,
    extraData: { role },
  } = getFieldProperties(fieldType)
  const designMapping = _.get(roleDesignMapping, [role, componentType])
  if(!designMapping)
    return {}

  return _.reduce(
    designMapping,
    (acc, styleParams, group: DESIGN_GROUPS) => {
      styleParams.forEach(param => {
        const groupValue = commonStyles[group]
        if (!groupValue) return
        if (getGroupType(group).includes('ALPHA')) {
          acc[`alpha-${param}`] = groupValue.alpha
        }

        acc[param] = groupValue.value
      })
      return acc
    },
    {}
  )
}

/* deprecated should be deleted when merging specs.cx.FormBuilderGlobalDesign */
const DEFAULT_COLOR = '#FFFFFF'
const COMMON_STYLES_MAPPING = {
  [FormStyle.FORM_BG_COLOR]: {
    isForm: true,
    primary: 'bg',
    default: '',
    getStyleParams: bg => ({ bg }),
  },
  [FormAlphaStyle.ALPHA_FORM_BG_COLOR]: {
    isForm: true,
    baseColor: FormStyle.FORM_BG_COLOR,
    primary: 'alpha-bg',
    default: '0',
    getStyleParams: alpha => ({ 'alpha-bg': alpha }),
  },
  [FormStyle.INPUT_BG_COLOR]: {
    primary: 'bg',
    default: 'color_11',
    getStyleParams: primaryColor => ({
      bg: primaryColor,
      bgf: primaryColor,
      bgh: primaryColor,
      emptyShapeColor: primaryColor,
    }),
  },
  [FormAlphaStyle.ALPHA_INPUT_BG_COLOR]: {
    baseColor: FormStyle.INPUT_BG_COLOR,
    primary: 'alpha-bg',
    default: '1',
    getStyleParams: alpha => ({
      'alpha-bg': alpha,
      'alpha-bgf': alpha,
      'alpha-bgh': alpha,
      'alpha-emptyShapeColor': alpha,
    }),
  },
  [FormStyle.INPUT_BORDER_COLOR]: {
    primary: 'brd',
    default: 'color_15',
    getStyleParams: primaryColor => ({
      brd: primaryColor,
      btn_brd: primaryColor, //eslint-disable-line camelcase
      brdh: primaryColor,
      brdf: primaryColor,
      shapeBorderColor: primaryColor,
    }),
  },
  [FormAlphaStyle.ALPHA_INPUT_BORDER_COLOR]: {
    baseColor: FormStyle.INPUT_BORDER_COLOR,
    primary: 'alpha-brd',
    default: '0.55',
    getStyleParams: alpha => ({
      'alpha-brd': alpha,
      'alpha-btn_brd': alpha,
      'alpha-brdh': alpha,
      'alpha-brdf': alpha,
      'alpha-shapeBorderColor': alpha,
    }),
  },
  [FormStyle.TEXT_COLOR]: {
    primary: 'txt',
    default: 'color_15',
    getStyleParams: primaryColor => {
      const clr = { primary: primaryColor, secondary: primaryColor }
      if (/^color_(\d)+$/.test(primaryColor)) {
        clr.secondary = `color_${parseInt(/(\d)+$/g.exec(primaryColor)[0]) - 1}`
      }
      return {
        txt: clr.primary,
        txt2: clr.secondary,
        'txt-placeholder': clr.secondary,
        filledShapeColor: clr.primary,
        labelFontColor: clr.primary,
      }
    },
  },
  [FormStyle.FONT]: {
    primary: 'fnt',
    default: 'font_8',
    getStyleParams: primaryFnt => {
      const font =
        _.includes(primaryFnt, FONT_STYLE_VALUE_PREFIX) || /^font_(\d)+$/.test(primaryFnt)
          ? primaryFnt
          : `${FONT_STYLE_VALUE_PREFIX}${primaryFnt.split(' ').join('+')}`
      return {
        fnt: font,
        fnt2: font,
        btn_fnt: font, //eslint-disable-line camelcase
        labelFont: font,
      }
    },
  },
  [FormStyle.BORDER_WIDTH]: {
    primary: 'brw',
    default: '1',
    getStyleParams: border => ({
      brw: border,
      brwe: border,
      brwd: border,
      brwf: border,
      brwh: border,
      btn_brw: border, //eslint-disable-line camelcase
    }),
  },

  [FormStyle.BORDER_RADIUS]: {
    primary: 'rd',
    default: '0',
    getStyleParams: radius => ({
      rd: `${radius}px`,
    }),
  },
}

const calcFormCommonStyle = (form, { result, styleConfig, styleName }) => {
  const styleValue = _.get(form, ['style', styleConfig.primary], styleConfig.default)
  const commonStyle = { [styleName]: styleValue }
  return { ...result, ...commonStyle }
}

export const calcFieldsCommonStyle = (fields, { result, styleConfig, styleName }) => {
  const cache = _.reduce(
    fields,
    (c, field) => {
      const paramValue = field.style[styleConfig.primary] || styleConfig.default
      const e = _.find(c, { paramValue })
      if (e) {
        e.count++
      } else {
        c.push({ paramValue, count: 1 })
      }
      return c
    },
    []
  )
  const majorityElement = <any>_.maxBy(cache, 'count')
  const styleValue = majorityElement ? majorityElement.paramValue : styleConfig.default
  const commonStyle = { [styleName]: styleValue }
  return { ...result, ...commonStyle }
}

export const calcCommonStyle = (form, fields) =>
  _.reduce(
    COMMON_STYLES_MAPPING,
    (result, styleConfig, styleName) =>
      _.get(styleConfig, 'isForm')
        ? calcFormCommonStyle(form, { result, styleConfig, styleName })
        : calcFieldsCommonStyle(fields, { result, styleConfig, styleName }),
    {}
  )

export const getStyleValues = (styleName, newStyleValue, commonStyles) => {
  const styleMapping = COMMON_STYLES_MAPPING[styleName]
  const styleParams = styleMapping.getStyleParams(newStyleValue)
  const baseColor = _.get(styleMapping, 'baseColor')
  if (baseColor && !commonStyles[baseColor]) {
    _.assign(styleParams, COMMON_STYLES_MAPPING[baseColor].getStyleParams(DEFAULT_COLOR))
  }
  return styleParams
}

export const isFormStyle = styleName => _.get(COMMON_STYLES_MAPPING[styleName], 'isForm')
/* ******************  */
