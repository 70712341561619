/* eslint-disable */
import { preset as contactFormPresetTranslated } from '../../../assets/presets/contact-form/preset.translated'
import { preset as getSubscribersPresetTranslated } from '../../../assets/presets/get-subscribers/preset.translated'
import { preset as potentialCustomersPresetTranslated } from '../../../assets/presets/potential-customers/preset.translated'
import { preset as salesLeadPresetTranslated } from '../../../assets/presets/sales-lead/preset.translated'
import { preset as supportRequestPresetTranslated } from '../../../assets/presets/support-request/preset.translated'
import { preset as testimonialPresetTranslated } from '../../../assets/presets/testimonial/preset.translated'
import { preset as jobApplicationPresetTranslated } from '../../../assets/presets/job-application/preset.translated'
import { preset as customerSatisfactionPresetTranslated } from '../../../assets/presets/customer-satisfaction/preset.translated'
import { preset as priceQuotePresetTranslated } from '../../../assets/presets/price-quote/preset.translated'
import { preset as contestEntryPresetTranslated } from '../../../assets/presets/contest-entry/preset.translated'
import { preset as quizPresetTranslated } from '../../../assets/presets/quiz/preset.translated'
import { preset as registrationFormPresetTranslated } from '../../../assets/presets/registration-form/preset.translated'
import { FormPreset } from '../../../constants/form-types'
import translations from '../services/translations'

export const getPresets = ({ customTranslations = null } = {}) => {
  const t = customTranslations ? customTranslations.t : translations.t

  return {
    [FormPreset.CONTACT_FORM]: contactFormPresetTranslated(t),
    [FormPreset.GET_SUBSCRIBERS]: getSubscribersPresetTranslated(t),
    [FormPreset.POTENTIAL_CUSTOMERS]: potentialCustomersPresetTranslated(t),
    [FormPreset.SALES_LEAD]: salesLeadPresetTranslated(t),
    [FormPreset.SUPPORT_REQUEST]: supportRequestPresetTranslated(t),
    [FormPreset.TESTIMONIAL]: testimonialPresetTranslated(t),
    [FormPreset.JOB_APPLICATION]: jobApplicationPresetTranslated(t),
    [FormPreset.CUSTOMER_SATISFACTION]: customerSatisfactionPresetTranslated(t),
    [FormPreset.PRICE_QUOTE]: priceQuotePresetTranslated(t),
    [FormPreset.CONTEST_ENTRY]: contestEntryPresetTranslated(t),
    [FormPreset.QUIZ]: quizPresetTranslated(t),
    [FormPreset.REGISTRATION_FORM]: registrationFormPresetTranslated(t),
  }
}

export const SHOULD_CREATE_COLLECTION = {
  [FormPreset.CONTACT_FORM]: false,
  [FormPreset.POTENTIAL_CUSTOMERS]: false,
  [FormPreset.SALES_LEAD]: false,
  [FormPreset.SUPPORT_REQUEST]: true,
  [FormPreset.TESTIMONIAL]: true,
  [FormPreset.JOB_APPLICATION]: true,
  [FormPreset.CUSTOMER_SATISFACTION]: true,
  [FormPreset.PRICE_QUOTE]: true,
  [FormPreset.CONTEST_ENTRY]: false,
  [FormPreset.QUIZ]: true,
  [FormPreset.REGISTRATION_FORM]: false,
}
