import { FieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const URL_REGEX = '^((https?|ftp)://)?[^\\s/$.?#]*\\.[^\\s]*$'

const makeGeneralURL = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.URL,
    componentType: COMPONENT_TYPES.TEXT_INPUT,
    extraData: {
      role: FIELDS.ROLE_FIELD_TEXT,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_URL}`),
      },
      data: {
        textType: 'text',
        pattern: URL_REGEX,
      },
      props: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
    },
  }
}

export const generalUrlData: FieldData = {
  make: makeGeneralURL,
  customFields: [customTypes.URL],
  icon: iconNames.website
}
