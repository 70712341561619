import * as _ from 'lodash'
import { getFieldProperties, FieldProperties } from './fields/field-types-data'
import { getPresets } from './presets-data'
import * as fields from '../../../assets/presets/fields.json'
import { FormPreset } from '../../../constants/form-types'
import { Theme } from '../../../constants/form-style';
import { FieldPreset } from '../../../constants/field-types';

const presetStyle = { width: '109px', height: '82px' }

const applyFieldData = (field, fieldType: FieldPreset, extraData: any = {}) => {
  const fieldProps: FieldProperties = getFieldProperties(fieldType)
  return _.merge({}, field, fieldProps.extraData, extraData, {
    connectionConfig: {
      fieldType,
      collectionFieldType: fieldProps.collectionFieldType,
    },
  })
}

export const getFormPreset = (presetKey: FormPreset) => getPresets()[presetKey]

export const getFieldPreset = (fieldType: FieldPreset, extraData) => {
  const componentType = getFieldProperties(fieldType).componentType
  return applyFieldData(fields[componentType], fieldType, extraData)
}

export const getGlobalDesignPresets = (baseUrl) => ({
  [Theme.THEME01]: {
    src: `${baseUrl}/form-01.png`,
    layout: presetStyle,
  },
  [Theme.THEME02]: {
    src: `${baseUrl}/form-02.png`,
    layout: presetStyle,
  },
  [Theme.THEME03]: {
    src: `${baseUrl}/form-03.png`,
    layout: presetStyle,
  },
  [Theme.THEME04]: {
    src: `${baseUrl}/form-04.png`,
    layout: presetStyle,
  },
  [Theme.THEME05]: {
    src: `${baseUrl}/form-05.png`,
    layout: presetStyle,
  },
  [Theme.THEME06]: {
    src: `${baseUrl}/form-06.png`,
    layout: presetStyle,
  },
})
