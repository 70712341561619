import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import * as signupButtonStructure from '../../../assets/presets/signup-button.json'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationFormMessageStructure from '../../../assets/presets/registration-form-message.json'
import { ButtonType, MessageType } from '../../../constants/field-types'

export const getSubmitButtonPreset = buttonType => {
  if (buttonType === ButtonType.SIGNUP) {
    return signupButtonStructure
  }
  return submitButtonStructure
}

export const getHiddenMessagePreset = messageType => {
  if (messageType === MessageType.REGISTRATION) {
    return registrationFormMessageStructure
  }
  return hiddenMessageStructure
}
