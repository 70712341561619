import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { generalDatePickerData } from '../../general-fields/definitions/general-date-picker'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

const makeBirthday = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalDatePickerData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.DATE,
        crmTag: CRM_TAGS.BIRTHDAY,
        crmLabel: t(`fieldTypes.${FieldPreset.BIRTHDAY}`),
      },
      props: { placeholder: t(`fieldTypes.${FieldPreset.BIRTHDAY}`) },
    },
  })
}

export const birthdayData: FieldData = {
  make: makeBirthday,
  customFields: [],
  icon: generalDatePickerData.icon
}
